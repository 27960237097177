@import "vars_aurelio";

.footer {
    font-family: 'Montserrat', Helvetica, sans-serif;
    margin-top: 100px;
    background-color: lighten($background-extra-light, 1%);
    border-top: 1px solid rgb(248, 248, 248);
}

.footer-wrap {
    padding-top: 40px;
}

.footer-title {
    width: 100%;
    margin-bottom: 54px;
    font-weight: 500;
    font-size: 16px;
}

.footer-title h2 {
    color: #ffffff;
    text-align: center;
    font-size: 16px;
}

.footer-title a {
    color: $light-text-color;
    font-weight: normal;
}

.footer-title a:hover {
    color: #ddd;
}

.footer-title p {
    color: $dark-text-color;
    font-weight: 100;
    font-size: 14px;
}

.footer-icons {
    display: block;
    padding: 0;
}

.footer-icons li {
    margin-right: 25px;
    display: inline-block;
}

.footer-icons li:last-child {
    margin-right: 0;
}

.footer-icons li a {
    position: relative;
    color: $icon-color;
    font-size: 16px;
    line-height: 30px;
    -webkit-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    z-index: 2;
    text-align: center;
    height: 30px;
    display: inline-block;
}

.footer-icons li a:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
}

.footer a:hover {
    color: #79CAF7;
    text-decoration: none;
}

.footer-icons li a:hover:after {
    opacity: 1 !important;
}

.logo-footer a img {
    display: block;
    width: 165px;
    height: 35px;
    margin-top: 40px;
}

.footer-column-title {
    padding-bottom: 30px;
}

.footer-wrap {
    padding: 40px 10px;
}
.footer-column {
    padding: 0 10px;
}
@media (max-width: 767px) {
    .footer-icons {
        float: left;
    }
    .logo-footer img {
        float: left;
        margin: 0 0 0 20px !important;
    }
    .col-md-4.col-sm-4.col-xs-12.footer-column .footer-column-title {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .footer-column {
        padding-left: 100px;
    }
}